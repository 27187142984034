export const getCountOfCartItems = (data) => {
  return {
    type: "countOfCartItems",
    count: data,
  };
};

export const getLoginDetailsData = (data) => {
  return {
    type: "getLoginDetails",
    data: data,
  };
};

export const storeForgotVar = (value) => {
  return {
    type: "storeForgotVar",
    value: value,
  };
};

export const storeFavCount = (storeFavCountVal) => {
  return {
    type: "storeFavCount",
    storeFavCount: storeFavCountVal,
  };
};

export const storeEntityId = (storeEntityId) => {
  return {
    type: "storeEntityId",
    storeEntityId: storeEntityId,
  };
};

export const isProcessToCheckoutClick = (isProcessToCheckoutClick) => {
  return {
    type: "isProcessToCheckoutClick",
    isProcessToCheckoutClick: isProcessToCheckoutClick,
  };
};

export const storeSectionUrl = (storeSectionUrl) => {
  return {
    type: "storeSectionUrl",
    storeSectionUrl: storeSectionUrl,
  };
};

export const storeGetLogo = (storeGetLogo) => {
  return {
    type: "storeGetLogo",
    storeGetLogo: storeGetLogo,
  };
};

export const isLoginModalOpen = (isLoginModalOpen) => {
  return {
    type: "isLoginModalOpen",
    isLoginModalOpen: isLoginModalOpen,
  };
};

export const isRegisterModalOpen = (isRegisterModalOpen) => {
  return {
    type: "isRegisterModalOpen",
    isRegisterModalOpen: isRegisterModalOpen,
  };
};

export const isGuestIdLogin = (isGuestIdLogin) => {
  return {
    type: "isGuestIdLogin",
    isGuestIdLogin: isGuestIdLogin,
  };
};

export const openGetCartNavbar = (openGetCartNavbar) => {
  return {
    type: "openGetCartNavbar",
    openGetCartNavbar: openGetCartNavbar,
  };
};

export const storeGetCartData = (storeGetCartData) => {
  return {
    type: "storeGetCartData",
    storeGetCartData: storeGetCartData,
  };
};

export const storeGetCartPrice = (storeGetCartPrice) => {
  return {
    type: "storeGetCartPrice",
    storeGetCartPrice: storeGetCartPrice,
  };
};

export const isCartLoginClick = (isCartLoginClick) => {
  return {
    type: "isCartLoginClick",
    isCartLoginClick: isCartLoginClick,
  };
};

export const isChangePasswordLoginClick = (isChangePasswordLoginClick) => {
  return {
    type: "isChangePasswordLoginClick",
    isChangePasswordLoginClick: isChangePasswordLoginClick,
  };
};

export const isJewelDIY = (isJewelDIY) => {
  return {
    type: "isJewelDIY",
    isJewelDIY: isJewelDIY,
  };
};

export const isDiamoDIY = (isDiamoDIY) => {
  return {
    type: "isDiamoDIY",
    isDiamoDIY: isDiamoDIY,
  };
};

export const storeFilterValueName = (storeFilterValueName) => {
  return {
    type: "storeFilterValueName",
    storeFilterValueName: storeFilterValueName,
  };
}

export const storeSubNavbarName = (storeSubNavbarName) => {
  return {
    type: "storeSubNavbarName",
    storeSubNavbarName: storeSubNavbarName,
  };
}

export const storeCurrency = (storeCurrency) => {
  return {
    type: "storeCurrency",
    storeCurrency: storeCurrency,
  };
}

export const storeColorData = (storeColorData) => {
  return {
    type: "storeColorData",
    storeColorData: storeColorData,
  };
}

export const getJourneyCatalogueId = (getJourneyCatalogueId) => {
  return {
    type: "getJourneyCatalogueId",
    getJourneyCatalogueId: getJourneyCatalogueId,
  };
}

export const activeStepperTab = (tabValue) => {
  return {
    type: "TAB_SELECTION",
    payload: tabValue,
  };
}

export const diamondSelectAction = (diamondObj) => {
  return {
    type: "DIAMOND_SELECT",
    payload: diamondObj,
  };
}

export const diamondParamsAction = (diamondParamsObject) => {
  return {
    type: "SET_DIAMOND_PARAMS",
    payload: diamondParamsObject,
  };
}

export const selectRingAction = (ringObj) => {
  return {
    type: "JEWEL_RING_SELECT",
    payload: ringObj,
  };
}

export const ringAndDiamondAction = (data) => {
  return {
    type: "SELECT_RING_AND_DIAMOND",
    payload: data,
  };
};

export const editDiamondAction = (data) => {
  return {
    type: "EDIT_DIY_DIAMOND",
    payload: data,
  };
};

